import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "gatsby-theme-yum-components/src/utils/font-awesome"

const Icon = ({ icon, size, className }) => {
  const [iconName, setIconName] = useState("")
  const [iconType, setIconType] = useState("")

  useEffect(() => {
    setIconName(icon.icon.replace("fa-", ""))
    setIconType(icon.type)
  }, [])

  return (
    <>
      {iconName !== "" ? (
        <FontAwesomeIcon
          icon={[iconType, iconName]}
          size={size}
          className={className}
        />
      ) : null}
    </>
  )
}

export default Icon
