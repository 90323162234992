import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import "moment/locale/de"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
// import Consent from "gatsby-theme-yum-components/src/components/technical/Consent"

// import "@fontsource/spartan"

import "@fontsource/spartan/500.css"
import "@fontsource/spartan/700.css"

const Layout = ({
  children,
  submenuItems,
  menuItems,
  venue,
  location,
  submenuAction,
}) => {
  useEffect(() => {
    if (document?.location?.pathname === "/storyblok-preview/") {
      setTimeout(() => {
        if (document.querySelector(".cm-btn.cm-btn-success")) {
          document.querySelector(".cm-btn.cm-btn-success").click()
        }
      }, 100)
    }
  })

  return (
    <>
      <Helmet bodyAttributes={{ class: "font-body" }}></Helmet>
      {/* <Consent /> */}
      <Sidebar
        siteTitle="Kultur Bad Vilbel"
        submenuItems={submenuItems}
        menuItems={menuItems}
        venue={venue}
        location={location}
        submenuAction={submenuAction}
      />
      {/* <Header siteTitle="Kultur Bad Vilbel" submenuItems={submenuItems} /> */}
      <main className="mb-32">{children}</main>
      <Footer venue={venue} />
    </>
  )
}

export default Layout
