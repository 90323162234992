import React, { useEffect, useRef } from "react"

import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)

const FadeInLinesReveal = ({ children, className }) => {
  const refFadeInLinesReveal = useRef(null)

  // useEffect(() => {
  //   let split = []
  //   if (refFadeInLinesReveal.current.children?.length > 0) {
  //     if (refFadeInLinesReveal.current.children[0].children?.length > 0) {
  //       const splitTargets = refFadeInLinesReveal.current.children[0].querySelectorAll(
  //         "p, ul"
  //       )
  //       for (let i = 0; i < splitTargets.length; i++) {
  //         let splitPart = new SplitText(splitTargets[i], {
  //           type: "lines",
  //         })
  //         split = split.concat(splitPart.lines)
  //       }
  //     } else {
  //       split = new SplitText(refFadeInLinesReveal.current.children[0], {
  //         type: "lines",
  //       })
  //       split = split.lines
  //     }
  //     if (split && split.length > 0) {
  //       gsap.from(split, {
  //         autoAlpha: 0,
  //         duration: 0.75,
  //         stagger: 0.15,
  //         ease: "power1.out",
  //         scrollTrigger: {
  //           trigger: refFadeInLinesReveal.current,
  //           toggleActions: "play none none reverse",
  //           start: "start 75%",
  //           end: "bottom 25%",
  //         },
  //       })
  //     }
  //   }
  // }, [])

  return (
    <div ref={refFadeInLinesReveal} className={className}>
      {children}
    </div>
  )
}

export default FadeInLinesReveal
