import React, { useState } from "react"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import Submenu from "./Submenu"
import useMediaQuery from "gatsby-theme-yum-components/src/hooks/useMediaQuery"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons"
import Menu from "./Menu"
import { Helmet } from "react-helmet"

const Sidebar = ({
  submenuItems = {},
  menuItems = {},
  venue,
  location,
  submenuAction,
}) => {
  const [mobileMenuState, setMobileMenuState] = useState("close")
  const [menuState, setMenuState] = useState("close")
  const [menuHoverState, setMenuHoverState] = useState(false)

  const isMobile = useMediaQuery(`(max-width: 1023px)`)

  const mobileMenuClickHandler = () => {
    if (mobileMenuState === "close") {
      setMobileMenuState("open")
    } else {
      setMobileMenuState("close")
      setMenuState("close")
    }
  }
  const menuClickHandler = () => {
    if (menuState === "close") {
      setMenuState("open")
    } else {
      setMenuState("close")
    }
  }
  const menuHoverHandler = () => {
    if (menuHoverState === false) {
      setMenuHoverState(true)
    } else {
      setMenuHoverState(false)
    }
  }

  let bodyClass = ""
  if (Helmet.peek()) {
    const helmetAttributes = Helmet.peek()
    bodyClass = helmetAttributes.bodyAttributes.class
  }

  return (
    <>
      {mobileMenuState === "open" && (
        <Helmet
          bodyAttributes={{
            class: bodyClass + " overflow-hidden",
          }}
        />
      )}
      <aside className="fixed z-40">
        {mobileMenuState === "open" && (
          <div className="fixed inset-0 transition-all bg-black bg-opacity-50 duration-400"></div>
        )}
        <div className="bg-primary">
          <span
            className={`${
              isMobile && "w-full bg-white"
            } fixed z-40 px-16 pt-4 pb-2`}
          >
            {menuItems.links?.length > 0 && (
              <button
                className="absolute hidden -ml-8 text-lg lg:block focus:outline-none"
                onClick={() => menuClickHandler()}
                onMouseEnter={() => menuHoverHandler(true)}
                onMouseLeave={() => menuHoverHandler(false)}
              >
                {menuState === "close" ? (
                  <FontAwesomeIcon icon={faBars} size="1x" className="" />
                ) : (
                  <FontAwesomeIcon icon={faTimes} size="1x" className="" />
                )}
              </button>
            )}
            <button
              className="absolute block -ml-8 text-lg lg:hidden focus:outline-none"
              onClick={() => mobileMenuClickHandler()}
            >
              {mobileMenuState === "close" ? (
                <FontAwesomeIcon
                  icon={faBars}
                  size="1x"
                  className="text-black"
                />
              ) : (
                <FontAwesomeIcon icon={faTimes} size="1x" className="" />
              )}
            </button>
            <Link
              link="/"
              className={`inline-block text-lg font-bold ${
                mobileMenuState === "close"
              } uppercase transition-colors duration-300 focus:outline-none text-black`}
            >
              Kultur in <br />
              Bad Vilbel
            </Link>
            {!isMobile && menuItems.links?.length > 0 && (
              <button
                className={`relative block mb-4 mt-4 font-bold cursor-pointer transition-all duration-500 ${
                  mobileMenuState === "close" ? "-ml-80 lg:ml-0" : ""
                } lg:mt-12 font-display lg:hidden focus:outline-none`}
                onClick={() => menuClickHandler()}
              >
                {menuState === "close" ? (
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    size="1x"
                    className="absolute block -ml-8 text-lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="1x"
                    className="absolute block mt-0.5 -ml-8 text-lg"
                  />
                )}
                Spielorte
              </button>
            )}
            {/* {menuItems.links?.length > 0 && (
            <button
              className={`relative block mb-4 mt-3 font-bold cursor-pointer transition-all duration-500 ${
                mobileMenuState === "close" ? "-ml-80 lg:ml-0" : ""
              } lg:mt-12 font-display`}
              onClick={() => menuClickHandler()}
            >
              {menuState === "close" ? (
                <FontAwesomeIcon
                  icon={faArrowToLeft}
                  size="1x"
                  className="absolute block -ml-8 text-lg"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faTimes}
                  size="1x"
                  className="absolute block -ml-8 text-lg"
                />
              )}
              Bereiche
            </button>
          )} */}
          </span>

          <div className="fixed z-30">
            <div className="relative flex flex-row flex-wrap">
              {menuItems.links?.length > 0 && (
                <Menu menuItems={menuItems} menuState={menuState} />
              )}

              <Submenu
                menuHoverState={menuHoverState}
                setMobileMenuState={setMobileMenuState}
                mobileMenuState={mobileMenuState}
                submenuItems={submenuItems}
                menuState={menuState}
                menuClickHandler={menuClickHandler}
                venue={venue}
                location={location}
                submenuAction={submenuAction}
              ></Submenu>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}

export default Sidebar
