import React, { useRef, useEffect } from "react"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import Scrollspy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { gsap } from "gsap"

import useStoryblokDatasource from "gatsby-theme-yum-components/src/hooks/useStoryblokDatasource"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronSquareLeft } from "@fortawesome/pro-solid-svg-icons"

const useSubmenuReveal = () => {
  const refSubmenuReveals = useRef([])
  refSubmenuReveals.current = []

  const refSubmenuRevealsCollection = (el) => {
    if (el && !refSubmenuReveals.current.includes(el)) {
      refSubmenuReveals.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refSubmenuReveals.current, {
      autoAlpha: 0,
      duration: 0.5,
      stagger: 0.1,
      ease: "power1.out",
    })
  }, [])
  return [refSubmenuRevealsCollection]
}

const Submenu = ({
  submenuItems = {},
  menuHoverState,
  setMobileMenuState,
  mobileMenuState,
  menuState,
  menuClickHandler,
  submenuAction,
  location,
}) => {
  const scrollTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }
  const { storyblokDatasourceLookup } = useStoryblokDatasource()

  const result =
    location &&
    location.pathname &&
    location.pathname.match(/\/([^\/]+)(\/?)(.?)/)
  const hasParent = result && result[3]
  const parentPath = result && `/${result[1]}`
  const parentTitle =
    result && result[1] && storyblokDatasourceLookup("venues", result[1])
  //result[1].charAt(0).toUpperCase() + result[1].slice(1)

  const [refSubmenuRevealsCollection] = useSubmenuReveal()

  const handleClose = () => {
    setMobileMenuState("close")
  }

  const handleFilter = (e) => {
    e.preventDefault()
    submenuAction(e.target.value)
    handleClose()
  }

  return (
    <>
      <div
        className={`absolute md:relative z-20 block h-screen bg-white ml-0 lg:ml-0 w-80 transition-all duration-500 ${
          mobileMenuState === "close" ? "-ml-80" : ""
        }`}
      >
        <div
          className={`absolute z-10 block transition-all duration-300 ${
            menuState === "close" && menuHoverState === true ? "w-12" : "w-8"
          } h-full bg-primary`}
        ></div>
        <div className="flex-col justify-end h-screen pt-32 pl-16 pr-8 overflow-y-scroll">
          {hasParent && (
            <Link
              className="relative inline-block mb-4 mb-16 font-bold cursor-pointer font-display"
              link={parentPath}
            >
              <FontAwesomeIcon
                icon={faChevronSquareLeft}
                size="1x"
                className="absolute block -ml-8 text-lg"
              />

              {parentTitle}
            </Link>
          )}
          {submenuItems.headline?.length > 0 && (
            <a
              className="relative block mb-4 font-bold cursor-pointer font-display"
              onClick={() => {
                setMobileMenuState("close")
                scrollTop()
              }}
              ref={refSubmenuRevealsCollection}
            >
              {/* <FontAwesomeIcon
                icon={faArrowToTop}
                size="1x"
                className="absolute block -ml-8 text-lg"
              /> */}
              {submenuItems.headline}
            </a>
          )}
          <nav className="">
            <Scrollspy
              items={submenuItems.links?.map((link) =>
                link?.url.replace(/\#/g, "")
              )}
              currentClassName="is-current"
              scrolledPastClassName="is-past"
              offset={-5}
            >
              {submenuItems.links?.map((item, index) => {
                return (
                  <li
                    className={`pb-4 ${item.venue && "theme-" + item.venue}`}
                    key={index}
                  >
                    <div ref={refSubmenuRevealsCollection}>
                      {item.type === "anchor" ? (
                        <AnchorLink
                          className="relative font-display"
                          onClick={() => {
                            setMobileMenuState("close")
                          }}
                          href={item?.url}
                        >
                          <span className="submenu-indicator"></span>
                          {item?.title}
                        </AnchorLink>
                      ) : (
                        <Link
                          className="relative font-display"
                          onClick={() => {
                            setMobileMenuState("close")
                          }}
                          link={item?.url}
                        >
                          <span className="submenu-indicator"></span>
                          {item?.title}
                        </Link>
                      )}
                    </div>
                  </li>
                )
              })}
              {submenuItems.filters &&
                submenuAction &&
                submenuItems.filters?.map((item, index) => (
                  <li
                    className={`pb-4 ${item.venue && "theme-" + item.venue}`}
                    key={index}
                  >
                    <div ref={refSubmenuRevealsCollection}>
                      <button
                        className="relative font-display"
                        onClick={handleFilter}
                        value={item.filter}
                      >
                        <span className="submenu-indicator"></span>
                        {item?.title}
                      </button>
                    </div>
                  </li>
                ))}
            </Scrollspy>
          </nav>
          {submenuItems.cta_title && (
            <div ref={refSubmenuRevealsCollection} className="mb-4">
              <Link
                link={
                  submenuItems.cta_link?.length > 0
                    ? submenuItems.cta_link
                    : "/schedule"
                }
                className="px-4 py-2 -ml-4 text-black bg-primary font-display hover:bg-primary-hover"
              >
                {submenuItems.cta_title}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default Submenu
