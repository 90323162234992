import React, { useRef, useEffect } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const BarReveal = ({ children, className }) => {
  const refBarRevealText = useRef(null)
  const refBarRevealBar = useRef(null)

  useEffect(() => {
    gsap.set(refBarRevealText.current, {
      autoAlpha: 0,
    })
    gsap.set(refBarRevealBar.current, {
      left: 0,
      right: 0,
    })
    const tl = gsap.timeline({
      defaults: { duration: 0.75, ease: "power3.inOut" },
      scrollTrigger: {
        trigger: refBarRevealText.current,
        toggleActions: "restart none none reverse",
        start: "bottom 90%",
        end: "top top",
      },
    })
    tl.to(refBarRevealBar.current, {
      width: "100%",
    })
    tl.set(refBarRevealText.current, {
      autoAlpha: 1,
    })
    tl.to(refBarRevealBar.current, {
      duration: 0,
      right: 0,
      left: "auto",
    })
    tl.to(refBarRevealBar.current, {
      width: "0",
    })
  }, [])

  return (
    <>
      <div className="relative">
        <span ref={refBarRevealText}>{children}</span>
        <span
          ref={refBarRevealBar}
          className={`${className} absolute top-0 block w-0 h-full bg-primary`}
        ></span>
      </div>
    </>
  )
}

export default BarReveal
