import React from "react"
import Button from "./Button"
import ImageReveal from "gatsby-theme-yum-components/src/components/reveals/ImageReveal"

const ButtonGroup = ({ className = "", buttons = [] }) => {
  return (
    <>
      {buttons.length > 0 && (
        <div className={`${className}`}>
          {buttons.map((button, index) => (
            <ImageReveal className="inline-block" key={index}>
              <Button
                className={`mb-4 ${index + 1 < buttons.length ? "mr-4" : ""}`}
                btnType={index > 0 ? "secondary" : "primary"}
                link={button.link}
                target={button.target}
                key={index}
                externalIcon={button.external_icon}
                icon={button.icon}
              >
                {button.link_text}
              </Button>
            </ImageReveal>
          ))}
        </div>
      )}
    </>
  )
}

export default ButtonGroup
