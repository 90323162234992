import React from "react"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

const Footer = ({ venue }) => {
  const socialmedia = {
    burgfestspiele: [
      {
        link_type: "facebook",
        link_text: "Burgfestspiele",
        link: "https://www.facebook.com/BurgfestspieleBadVilbel/",
      },
      {
        link_type: "instagram",
        link_text: "Burgfestspiele",
        link: "https://www.instagram.com/burgfestspiele_badvilbel/",
      },
    ],
    "kino-alte-muehle": [
      {
        link_type: "instagram",
        link_text: "Kino Alte Mühle",
        link: "https://www.instagram.com/kinoaltemuehle/",
      },
    ],
    "theater-alte-muehle": [
      {
        link_type: "facebook",
        link_text: "Theater Alte Mühle",
        link: "https://www.facebook.com/Theater-Alte-M%C3%BChle-257848177591403",
      },
      {
        link_type: "facebook",
        link_text: "Restaurantcafé",
        link: "https://www.facebook.com/RestaurantcafeAlteMuehle/",
      },
    ],
    stadtbibliothek: [
      {
        link_type: "instagram",
        link_text: "Stadtbibliothek",
        link: "https://www.instagram.com/stadtbibliothekbv/",
      },
    ],
    vilco: [
      {
        link_type: "instagram",
        link_text: "Kultur Bad Vilbel",
        link: "https://www.instagram.com/kultur_bad_vilbel/",
      },
      {
        link_type: "facebook",
        link_text: "Kultur Bad Vilbel",
        link: "https://www.facebook.com/kultur.bad.vilbel",
      },
    ],
    "open-air-kino": [
      {
        link_type: "facebook",
        link_text: "Open Air Kino",
        link: "https://www.facebook.com/OpenAirKinoImBadVilbelerFreibad/",
      },
      {
        link_type: "instagram",
        link_text: "Open Air Kino",
        link: "https://www.instagram.com/openairkinobadvilbel/",
      },
    ],
  }

  return (
    <footer className="pt-8 border-t-8 pb-28 lg:pb-4 border-primary">
      <section className=" block-w-container">
        <div className="lg:pl-4">
          <div className="flex flex-row flex-wrap mb-4 leading-loose prose max-w-none -mx-grid">
            <div className="w-full px-grid">
              <span className="font-bold">KARTENBÜRO</span>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 px-grid">
              Kartenbüro Bad Vilbel
              <br />
              Klaus-Havenstein-Weg 1
              <br />
              61118 Bad Vilbel
            </div>
            <div className="w-full sm:w-1/2 md:w-2/3 px-grid">
              Tel: <a href="tel:06101559455">06101 / 559455</a>
              <br />
              Fax: 06101 / 559430
              <br />
              <Link link="tickets@bad-vilbel.de">tickets@bad-vilbel.de</Link>
            </div>
          </div>
        </div>
      </section>
      {socialmedia[venue] && (
        <section className="pt-4 mb-4 bg-primary">
          <div className=" block-w-container">
            <div className="lg:pl-4">
              <div className="flex flex-row flex-wrap -mx-grid">
                {socialmedia[venue] &&
                  socialmedia[venue].map((item, index) => (
                    <div className="sm:w-1/2 lg:w-1/3 px-grid" key={index}>
                      <Link
                        className="inline-block mb-4 hover:underline"
                        link={item.link}
                      >
                        {item.link_type === "facebook" ? (
                          <>
                            <span className="sr-only">Facebook</span>
                            <FontAwesomeIcon
                              icon={faFacebook}
                              size="1x"
                              className="mr-2 text-xl"
                            />
                          </>
                        ) : item.link_type === "instagram" ? (
                          <>
                            <span className="sr-only">Instagram</span>
                            <FontAwesomeIcon
                              icon={faInstagram}
                              size="1x"
                              className="mr-2 text-xl"
                            />
                          </>
                        ) : (
                          ""
                        )}
                        {item.link_text}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      )}
      <section className=" block-w-container">
        <div className="lg:pl-4">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="order-last w-full sm:order-first sm:w-1/2 px-grid">
              © {new Date().getFullYear()} Stadt Bad Vilbel
            </div>
            <div className="w-full mb-4 prose sm:mb-0 sm:text-right sm:w-1/2 px-grid">
              <Link className=" hover:underline" link="/datenschutz">
                Datenschutz
              </Link>
              <Link className="ml-4 hover:underline" link="/impressum">
                Impressum
              </Link>
              <Link
                className="ml-4 hover:underline"
                link="/"
                onClick={(e) => {
                  e.preventDefault()
                  window.klaro.show(window.klaroConfig)
                }}
              >
                Cookie-Konfiguration
              </Link>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
