import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const useStoryblokDatasource = () => {
  const { allStoryblokDatasourceEntry } = useStaticQuery(
    graphql`
      query datasources {
        allStoryblokDatasourceEntry {
          nodes {
            name
            value
            data_source
          }
        }
      }
    `
  )

  const storyblokDatasourceLookup = (data_source, value) => {
    const element = allStoryblokDatasourceEntry.nodes.find(
      (el) => el.data_source === data_source && el.value === value
    )

    return element ? element.name : value
  }

  return { storyblokDatasourceLookup }
}

export default useStoryblokDatasource
