import React from "react"
import HeadlineReveal from "gatsby-theme-yum-components/src/components/reveals/HeadlineReveal"
import TextReveal from "gatsby-theme-yum-components/src/components/reveals/TextReveal"

const Headline = ({
  kicker,
  headline,
  headline_level = "h2",
  subheadline,
  className,
}) => {
  const hTag = headline_level.length > 0 ? headline_level : "h2"
  return (
    <>
      {(kicker || headline || subheadline) && (
        <div className={className}>
          {kicker && kicker.length > 0 && (
            <TextReveal className="kicker">
              <span className={`kicker-${hTag}`}>{kicker}</span>
            </TextReveal>
          )}

          {headline &&
            headline.length > 0 &&
            React.createElement(
              hTag,
              {
                // ref: refHeadlineReveal,
                className:
                  "headline headline-" +
                  headline_level +
                  " relative inline-block py-2",
              },
              <HeadlineReveal className="headline">{headline}</HeadlineReveal>
            )}
          {subheadline && subheadline.length > 0 && (
            <TextReveal className="subheadline">
              <span className={`subheadline-${hTag}`}>{subheadline}</span>
            </TextReveal>
          )}
        </div>
      )}
    </>
  )
}

export default Headline
