import React, { useRef, useEffect } from "react"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const SlideRightReveal = ({ children, className }) => {
  const refSlideRightReveal = useRef(null)

  useEffect(() => {
    gsap.from(refSlideRightReveal.current, {
      width: "100%",
      duration: 0.75,
      ease: "power1.out",
      scrollTrigger: {
        trigger: refSlideRightReveal.current,
        toggleActions: "play none none reverse",
        start: "start 75%",
        end: "bottom 25%",
      },
    })
  }, [])

  return (
    <div className={`${className} relative overflow-hidden`}>
      {children}
      <div
        ref={refSlideRightReveal}
        className="absolute bottom-0 w-0 h-full origin-right bg-white"
      ></div>
    </div>
  )
}

export default SlideRightReveal
