import React, { useRef, useEffect } from "react"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"

import { gsap } from "gsap"
import Button from "gatsby-theme-yum-components/src/components/atoms/Button"

const useMenuReveal = () => {
  const refMenuReveals = useRef([])
  refMenuReveals.current = []

  const refMenuRevealsCollection = (el) => {
    if (el && !refMenuReveals.current.includes(el)) {
      refMenuReveals.current.push(el)
    }
  }

  useEffect(() => {
    gsap.from(refMenuReveals.current, {
      autoAlpha: 0,
      duration: 0.5,
      stagger: 0.1,
      ease: "power1.out",
    })
  }, [])
  return [refMenuRevealsCollection]
}

const Menu = ({ menuItems = {}, menuState }) => {
  const [refMenuRevealsCollection] = useMenuReveal()
  return (
    <div
      className={`absolute md:relative z-30 block h-screen ml-0 w-80 transition-all duration-500 ${
        menuState === "close" ? "-ml-80" : ""
      }`}
    >
      <div className="flex-col justify-end h-screen pt-32 pl-16 pr-8 bg-gray-500">
        <nav className="">
          <ul>
            {menuItems.links?.map((item, index) => (
              <li
                className={`pb-4 ${item.venue && "theme-" + item.venue}`}
                key={index}
              >
                <div ref={refMenuRevealsCollection}>
                  <Link className="relative font-display" link={item?.url}>
                    <span className="submenu-indicator"></span>
                    {item?.title}
                  </Link>
                </div>
              </li>
            ))}
            <li>
              <div ref={refMenuRevealsCollection}>
                <Button link="/schedule">Veranstaltungskalender</Button>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Menu
