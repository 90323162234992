import { useState, useLayoutEffect } from "react"

const useMediaQuery = (mediaQuery) => {
  if (typeof window !== "undefined") {
    const [matches, setMatches] = useState(
      () => window.matchMedia(mediaQuery).matches
    )

    useLayoutEffect(() => {
      const media = window.matchMedia(mediaQuery)
      media.matches !== matches && setMatches(media.matches)
    }, [matches, mediaQuery])
    return matches
  }
}

export default useMediaQuery
