import React from "react"
import Link from "./Link"

import Icon from "./Icon"

const Button = ({
  children,
  link,
  externalIcon,
  icon,
  className,
  target,
  btnType = "primary",
}) => {
  const baseClasses = "btn group "
  const typeClasses = {
    primary: "btn-primary",
    secondary: "btn-secondary",
  }

  if (link) {
    return (
      <Link
        className={`${baseClasses} ${typeClasses[btnType]} ${
          className ? className : ""
        }`}
        link={link}
        externalIcon={externalIcon}
        target={target}
      >
        {icon !== undefined && <Icon icon={icon} size="1x" className="mr-2" />}
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={`${baseClasses} ${typeClasses[btnType]} ${
          className ? className : ""
        }`}
      >
        {icon !== undefined && <Icon icon={icon} size="1x" className="mr-2" />}
        {children}
      </button>
    )
  }
}

export default Button
